<template>
  <div>
    <v-card
        flat
        tile
    >
      <v-toolbar color="customBlue" class="custom-bg px-10 py-5" elevation="0" dense>
        <router-link class="router-link d-block pa-0" to="/" rel="prefetch">
          <span class="logo py-2">
            <img :src="googleAdsLogo" alt="PivotAd Logo" width="165" height="50" loading="lazy"/>
          </span>
        </router-link>
      </v-toolbar>
    </v-card>
    <v-app>
      <v-container fluid class="d-flex align-center justify-space-between hero-section px-10">
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <h1 class="font-family-manrope-bold text-banner-title">
              Start advertising on Google with ease using PivotAd
            </h1>
            <p class="mt-10 mb-8 font-family-outfit-regular text-subtitle">
              Just provide your business URL and top 3 keywords, and our AI platform will create and launch your campaigns in minutes. No research or analysis needed, as PivotAd takes care of it all.
            </p>

            <div class="d-flex align-center email-container">
              <input type="email" placeholder="Enter your email" class="email-input">
              <v-btn
                :color="'#10A6FA'"
                class="text-capitalize rounded-btn"
                elevation="0"
              >
                GET STARTED
              </v-btn>
            </div>
            <v-btn
              :color="'#10A6FA'"
              class="text-capitalize rounded-btn-clone mt-2"
              elevation="0"
            >
              GET STARTED
            </v-btn>
            <div class="mt-3 font-family-outfit-regular text-service d-flex">
              <span class="d-flex align-center">
                <v-icon size="20" :color="'#10A6FA'" left>mdi-check</v-icon> 14 day free trial
              </span>

              <span class="d-flex align-center">
                <v-icon size="20" :color="'#10A6FA'" left>mdi-check</v-icon> No credit card required
              </span>
              <span class="d-flex align-center">
                <v-icon size="20" :color="'#10A6FA'" left>mdi-check</v-icon> Cancel anytime
              </span>

            </div>
          </v-col>

          <v-col cols="12" md="6" class="text-right">
            <img :src="bannerImg" alt="Marketing Illustration" class="illustration mt-10 ml-10">
          </v-col>
        </v-row>
      </v-container>
    </v-app>
    <v-card flat tile>
      <div class="ad-platforms px-10">
        <span class="text-ad-platforms font-family-outfit-regular">
          Optimize your digital marketing with AI-driven automation, seamlessly integrating with major ad platforms
        </span>
        <div class="logo-ad-platforms">
          <img :src="googleLogo" alt="Google Platforms">
          <img :src="metaLogo" alt="Meta Platforms">
          <img :src="bingLogo" alt="Bing Platforms">
          <img :src="tiktokLogo" alt="Tik-tok Platforms">
        </div>
      </div>
    </v-card>
    <v-card flat tile>
      <div class="better-adds">
        <h1 class="font-family-manrope-bold title-better-adds">
          Better Ad Copy <span style="color: #3D7FFF"> Boosts ROAS by 15-30% </span> for Google Ads, Facebook, Linkedin
          and Tiktok Ads
        </h1>
        <div>
          <div class="better-adds-section">
            <div>
              <h2 class="font-family-manrope-bold">Original Ad</h2>
              <img :src="orginalCard" alt="original ad banner">
            </div>
            <div>
              <h2 class="font-family-manrope-bold"> Generated by Pivotad.ai</h2>
              <img :src="generatedCard" alt="Generated ad banner">
            </div>
          </div>
          <v-btn
              :color="'#10A6FA'"
              class="text-capitalize trying-btn"
              elevation="0"
          >
            Try it free
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-card flat tile>
      <div class="benefits-section d-flex flex-column">
        <v-row class="benefit-feature">
          <v-col md="6" sm="12" class="d-flex justify-start">
            <div class="d-flex flex-column justify-center w-80p">
              <h2 class="font-family-manrope-bold save text-banner-title">Save with Pivotad.ai</h2>
              <span class="d-flex align-center affordable font-family-outfit-regular">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                  <rect x="1" y="1" width="26" height="26" rx="13" stroke="#2981E9" stroke-width="2"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z" fill="#2981E9"/>
                </svg>
                <span class="font-family-manrope-bold">The most affordable solution on the market</span>
              </span>
              <div class="font-family-outfit-regular">
                <p>Compare and see for yourself:</p>
                <div>
                  <p>- Freelancer: at least $500/mo</p>
                  <p>- Agency: $1000/mo</p>
                  <p>- In-house Expert: $2000/mo</p>
                </div>
                <p>Choose Innovative AI: Leverage best-in-class Generative AI for your marketing campaigns</p>
                <p>No Digital Marketing Knowledge Needed: Save time and let Pivotad.ai handle everything for you!</p>
              </div>
            </div>
          </v-col>
          <v-col md="6" sm="12">
            <v-img :src="saveFeature" alt="Save feature"/>
          </v-col>
        </v-row>
        <v-row class="benefit-feature">
          <v-col md="6" sm="12">
            <v-img :src="benefitFeature" alt="Save feature"/>
          </v-col>
          <v-col md="6" sm="12" class="d-flex justify-end reverse">
            <div class="d-flex flex-column justify-center w-80p">
              <h2 class="font-family-manrope-bold text-banner-title">
                How These Features Benefit You
              </h2>
              <div style="width: fit-content;" class="font-family-outfit-regular">
                <p class="d-flex align-center">
                  <img :src="checkIcon" alt="check icon" style="width: unset">
                  <span class="ml-2">Save Money: Reduce costs by at least $471 per month</span>
                </p>
                <p class="d-flex align-center">
                  <img :src="checkIcon" alt="check icon" style="width: unset">
                  <span class="ml-2">Save Time: Reclaim up to 8 hours per week</span>
                </p>
                <p class="d-flex align-center">
                  <img :src="checkIcon" alt="check icon" style="width: unset">
                  <span class="ml-2">Achieve Your Goals: Generate 3x more business</span>
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="benefit-feature">
          <v-col md="6" sm="12" class="d-flex justify-start">
            <div class="d-flex flex-column justify-center w-80p">
              <h2 class="font-family-manrope-bold text-banner-title">Solving Your Pain Points</h2>
              <p class="d-flex font-family-outfit-regular">
                <img :src="checkIcon" alt="check icon" style="width: unset">
                <span class="ml-2">User-Friendly: No prior Google Ads experience needed. Launch campaigns in minutes.</span>
              </p>
              <p class="d-flex align-center font-family-outfit-regular">
                <img :src="checkIcon" alt="check icon" style="width: unset">
                <span class="ml-2">Delivering Desired Results:</span>
              </p>
              <div class="font-family-outfit-regular" style="width: 80%">
                <p>- Boost Sales: 3x increase in sales</p>
                <p>- Increase Orders: 5x more orders</p>
                <p>- Maximize ROAS: Achieve 3-7x Return on Ad Spend (ROAS) </p>
              </div>
            </div>
          </v-col>
          <v-col md="6" sm="12">
            <v-img :src="painPoints" alt="Save feature"/>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card flat tile>
      <div class="reasons-pivot">
        <div class="reasons-pivot-title">
          <h2 class="font-family-manrope-bold title-better-adds">
            Why choose Pivot.ad
          </h2>
          <p class="font-family-outfit-regular">Our company, with 17+ years of experience in digital marketing, is committed to developing an advanced AI based ad platform to simplify digital advertising for SMBs. </p>
        </div>
        <div class="reasons-pivot-content">
          <div class="d-flex reasons-content-item">
            <div class="reason-icon-bg d-flex">
              <img :src="timeIcon" alt="Time settings">
            </div>
            <div>
              <h3 class="font-family-outfit-regular mb-4">Generate Your Ad Copy in Minutes</h3>
              <p class="font-family-outfit-regular">Enjoy the ease of automatic tools that handle everything from ad setup to continuous optimization, saving you time and effort</p>
            </div>
          </div>
          <div class="d-flex reasons-content-item">
            <div class="reason-icon-bg d-flex">
              <img :src="aiIcon" alt="Time settings">
            </div>
            <div>
              <h3 class="font-family-outfit-regular mb-4">Generate Your Ad Copy in Minutes</h3>
              <p class="font-family-outfit-regular"> Enjoy the ease of automatic tools that handle everything from ad setup to continuous optimization, saving you time and effort</p>
            </div>
          </div>
          <div class="d-flex reasons-content-item">
            <div class="reason-icon-bg d-flex">
              <img :src="chartIcon" alt="Time settings">
            </div>
            <div>
              <h3 class="font-family-outfit-regular mb-4">AI Copywriting for Marketing Performance</h3>
              <p class="font-family-outfit-regular">Boost ROAS by 30-50% with Enhanced Copy for Google Ads</p>
            </div>
          </div>
          <div class="d-flex reasons-content-item">
            <div class="reason-icon-bg d-flex">
              <img :src="newsIcon" alt="Time settings">
            </div>
            <div>
              <h3 class="font-family-outfit-regular mb-4">AI-Powered Copy Optimization for Every Channel</h3>
              <p class="font-family-outfit-regular">Save time, scale efficiently, and stay ahead of the competition with optimized copy for all your channels: Google, Meta, and Tiktok Ads</p>
            </div>
          </div>
          <div class="d-flex reasons-content-item">
            <div class="reason-icon-bg d-flex">
              <img :src="moneyIcon" alt="Time settings">
            </div>
            <div>
              <h3 class="font-family-outfit-regular mb-4">Effortless Campaign Management</h3>
              <p class="font-family-outfit-regular">Spend less and get more with our intelligent tools that optimize your ad spend, ensuring you attract more customers efficiently</p>
            </div>
          </div>
          <div class="d-flex reasons-content-item">
            <div class="reason-icon-bg d-flex">
              <img :src="alogIcon" alt="Time settings">
            </div>
            <div>
              <h3 class="font-family-outfit-regular mb-4">Dynamic Ad Optimization</h3>
              <p class="font-family-outfit-regular">Benefit from our smart algorithms that promote the best-performing ads, ensuring your campaigns continuously improve and yield better results</p>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <v-card flat tile>
      <div class="feedback-section">
        <h2 class="font-family-manrope-bold title-better-adds">
          Success stories
        </h2>
        <v-container class="pt-15">
          <v-carousel>
            <template v-for="(feedback, index) in feedbacks">
              <v-carousel-item v-if="(index % columns) === 0"  :key="index">
                <v-row class="flex-nowrap" style="height:100%">
                  <template v-for="i in columns">
                    <v-col v-if="(index + i - 1) < feedbacks.length" :key="i">
                      <v-card outlined class="pa-5 d-flex">
                        <v-avatar size="64">
                          <img :src="feedbacks[index + i - 1].image" alt="User Image" />
                        </v-avatar>
                        <div class="ml-3">
                          <blockquote class="font-family-outfit-regular">"{{ feedbacks[index + i - 1].text }}"</blockquote>
                          <div class="font-weight-bold font-family-outfit-regular">{{ feedbacks[index + i - 1].name }}</div>
                        </div>
                      </v-card>
                    </v-col>
                  </template>
                </v-row>
              </v-carousel-item>
            </template>
          </v-carousel>

        </v-container>
      </div>
    </v-card>
    <v-card tile flat>
      <div class="footer">
        <v-toolbar color="customBlue" class="custom-bg" elevation="0" dense>
          <router-link class="router-link d-block pa-0" to="/" rel="prefetch">
            <span class="logo py-2">
              <img :src="googleAdsLogo" alt="PivotAd Logo" width="165" height="50" loading="lazy"/>
            </span>
          </router-link>
          <div class="d-flex align-center social-media">
            <a href="#">
              <img :src="instaIcon" alt="Instagramm icon" loading="lazy"/>
            </a>
            <a href="#">
              <img :src="tikTokIcon" alt="Tik-tok icon" loading="lazy"/>
            </a>
            <a href="#">
              <img :src="fbIcon" alt="Facebook icon" loading="lazy"/>
            </a>
            <a href="#">
              <img :src="ytIcon" alt="You-Tube icon" loading="lazy"/>
            </a>
          </div>
        </v-toolbar>
        <div class="d-flex justify-space-between font-family-outfit-regular">
          <p>Pivotad © 2024</p>
          <div class="d-flex footer-menus">
            <a href="#">Terms of Service</a>
            <a href="#">Privacy Policy</a>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import googleAdsLogo from "../../assets/icon/Pivotadd_logo.svg";
import bannerImg from "../../assets/objects.png";
import googleLogo from "../../assets/logos/Google_Platforms.svg"
import metaLogo from "../../assets/logos/Meta_Platforms.svg";
import bingLogo from "../../assets/logos/Bing_Platforms.svg";
import tiktokLogo from "../../assets/logos/tiktok_Platforms.svg";
import orginalCard from '../../assets/img/cart_lable1.png';
import generatedCard from '../../assets/img/cart_lable2.png';
import generatedAdd from '../../assets/img/generated_add.svg';
import originalAdd from '../../assets/img/original_add.svg';
import saveFeature from '../../assets/img/features/save_feature.svg';
import benefitFeature from '../../assets/img/features/benefit_feature.svg';
import painPoints from '../../assets/img/features/pain_points.svg';
import checkIcon from '../../assets/img/features/check_icon.svg';
import timeIcon from '../../assets/img/reasons/time-setting.svg'
import aiIcon from '../../assets/img/reasons/elements.svg';
import chartIcon from '../../assets/img/reasons/line-chart-up.svg';
import newsIcon from '../../assets/img/reasons/news.svg';
import moneyIcon from '../../assets/img/reasons/money-receive-circle.svg'
import alogIcon from '../../assets/img/reasons/algorithm.svg'
import instaIcon from '../../assets/img/social-media/insta.svg'
import tikTokIcon from '../../assets/img/social-media/TikTok.svg'
import fbIcon from '../../assets/img/social-media/fb.svg'
import ytIcon from '../../assets/img/social-media/YT.svg'

export default {
  data: () => ({
    googleAdsLogo,
    bannerImg,
    googleLogo,
    metaLogo,
    bingLogo,
    tiktokLogo,
    orginalCard,
    generatedCard,
    generatedAdd,
    originalAdd,
    saveFeature,
    benefitFeature,
    painPoints,
    checkIcon,
    timeIcon,
    aiIcon,
    chartIcon,
    newsIcon,
    moneyIcon,
    alogIcon,
    instaIcon,
    tikTokIcon,
    fbIcon,
    ytIcon,
    feedbacks: [
      {
        image: "https://s3-alpha-sig.figma.com/img/397f/eae8/54e42d106668c5a6612d753baec5043e?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=diHsh1ftmCcSAfgcJfaHNpDpt0yl3ZOXNYOZoeOugH3TGMW2mdotCSif7TaMh59jYGs1JOsWIyPzIQlL6upFzVs2RRhDTjBdw6w-Xa8RtgZkZwFoj870qkroWUhbCxZuPPqX0uy4TCXIMCs1z4xhnTPQEHHqZxNy0JCgjpT8XEcIj-Fle0BeN7s0ycjkhOLSJfG~ek2kl0LpWtGGy1Lq4odIlVYceai990ss9JRqa0-d6jPg1DtIfrd1bwyIuVPVQ-BnqLjzk2QwW2INiGubYnXBhll-mlFeDcENAuqfey~dmD2FimTrtT-V3V6bCCanafjrfRkK2dgrBItqbLml2g__",
        text: "The best Google Ads automation tool I have ever used! Pivotad.ai's advanced features and ease of use have transformed my ad campaigns to high performers",
        name: "Jack",
      },
      {
        image: "https://s3-alpha-sig.figma.com/img/ae34/050d/59c330d03da2251da6ec4e61e9204ec6?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=aVn6eeLsNBf3pc315Q6H0dkMJIT8rVeYBmnwNI6Z8ihZQCjcd0Ibtiqt2hLdv7vsqqZPjR3TxygO5nlODmUcZYf8N4XhgTUs-zuQyJbMJnEVhxWg0p6gKOUwi6reMD-XJH~0~8zjdW5DeYZ7bNctQsXmsYoNYwdphYZ8fhUuvl4Mxlt50g08DLbUb6Ar4JFaCFWOhQ4kJD1YrfZ6ULVcx7Rj0nuDXxLWp1X70tYs2Dp1rDmnAE4EdW8ePNc4WxN67mBMlzLWpoJShsPa8ovKEq6W1SvcxQcdSXcdfI222eZ7FgjjVxWWPHv6W8KwjKaXNTeaje6uj8yJBRbj1D611A__",
        text: "“Pivotad.ai has been a game-changer, saving me significant time and money. The automation process is seamless and incredibly efficient”",
        name: "Mike",
      },
      {
        image: "https://s3-alpha-sig.figma.com/img/ed33/aff3/df2e468c44eaddb1896b43ac040fa942?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=clQGsQHqtR-VaWipnERYY~cBw1fZDfcHJOFg8AQIpTOARyuzYthWRojwc4OG2MZphqNKWf5TkagZ7kPMLKOIx4liPUxJMiOtcu5Fj1rDlb~QP3JBFrvkTroCu5JOAvNmK20fQZ7TqTwO3SVN-mrUhTsNKuXOACfqYiva4EoZeadUUaQ3H9ZvNxoOYmXHTXVhW-JRYybR9yNbLJ~OwsLVLRB-RRUSMP94xASOwb-7v-VTm~rKiU6je9V8GAI2xc2-JDOHaZ0igYOnZNgSdwO-C1Ae2ET4wVyZ5IM1eNeNuDQ5vgwUNTWkn7~b~nbgebMh8VsXQyqGD9~A1F5NR~zOAw__",
        text: "Thanks to Pivotad.ai, I achieved a 5x increase in sales within the first 30 days. This platform truly maximizes results and delivers outstanding performance",
        name: "Alla",
      },
      {
        image: "https://s3-alpha-sig.figma.com/img/ae34/050d/59c330d03da2251da6ec4e61e9204ec6?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=aVn6eeLsNBf3pc315Q6H0dkMJIT8rVeYBmnwNI6Z8ihZQCjcd0Ibtiqt2hLdv7vsqqZPjR3TxygO5nlODmUcZYf8N4XhgTUs-zuQyJbMJnEVhxWg0p6gKOUwi6reMD-XJH~0~8zjdW5DeYZ7bNctQsXmsYoNYwdphYZ8fhUuvl4Mxlt50g08DLbUb6Ar4JFaCFWOhQ4kJD1YrfZ6ULVcx7Rj0nuDXxLWp1X70tYs2Dp1rDmnAE4EdW8ePNc4WxN67mBMlzLWpoJShsPa8ovKEq6W1SvcxQcdSXcdfI222eZ7FgjjVxWWPHv6W8KwjKaXNTeaje6uj8yJBRbj1D611A__",
        text: "“Pivotad.ai has been a game-changer, saving me significant time and money. The automation process is seamless and incredibly efficient”",
        name: "Mike",
      },
      {
        image: "https://s3-alpha-sig.figma.com/img/ed33/aff3/df2e468c44eaddb1896b43ac040fa942?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=clQGsQHqtR-VaWipnERYY~cBw1fZDfcHJOFg8AQIpTOARyuzYthWRojwc4OG2MZphqNKWf5TkagZ7kPMLKOIx4liPUxJMiOtcu5Fj1rDlb~QP3JBFrvkTroCu5JOAvNmK20fQZ7TqTwO3SVN-mrUhTsNKuXOACfqYiva4EoZeadUUaQ3H9ZvNxoOYmXHTXVhW-JRYybR9yNbLJ~OwsLVLRB-RRUSMP94xASOwb-7v-VTm~rKiU6je9V8GAI2xc2-JDOHaZ0igYOnZNgSdwO-C1Ae2ET4wVyZ5IM1eNeNuDQ5vgwUNTWkn7~b~nbgebMh8VsXQyqGD9~A1F5NR~zOAw__",
        text: "Thanks to Pivotad.ai, I achieved a 5x increase in sales within the first 30 days. This platform truly maximizes results and delivers outstanding performance",
        name: "Alla",
      },
      {
        image: "https://s3-alpha-sig.figma.com/img/397f/eae8/54e42d106668c5a6612d753baec5043e?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=diHsh1ftmCcSAfgcJfaHNpDpt0yl3ZOXNYOZoeOugH3TGMW2mdotCSif7TaMh59jYGs1JOsWIyPzIQlL6upFzVs2RRhDTjBdw6w-Xa8RtgZkZwFoj870qkroWUhbCxZuPPqX0uy4TCXIMCs1z4xhnTPQEHHqZxNy0JCgjpT8XEcIj-Fle0BeN7s0ycjkhOLSJfG~ek2kl0LpWtGGy1Lq4odIlVYceai990ss9JRqa0-d6jPg1DtIfrd1bwyIuVPVQ-BnqLjzk2QwW2INiGubYnXBhll-mlFeDcENAuqfey~dmD2FimTrtT-V3V6bCCanafjrfRkK2dgrBItqbLml2g__",
        text: "The best Google Ads automation tool I have ever used! Pivotad.ai's advanced features and ease of use have transformed my ad campaigns to high performers",
        name: "Jack",
      }

    ],
  }),
  computed: {
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }

      if (this.$vuetify.breakpoint.md) {
        return 2;
      }

      return 1;
    }
  },
}
</script>
<style lang="scss" scoped>
.custom-bg{
  height: 88px!important;
}
:deep(.v-toolbar__content){
  padding: 0!important;
}
:deep(.v-application--wrap){
  min-height: unset!important;
}
.hero-section {
  background: url("../../assets/gradient_background.png") no-repeat center;
  background-size: cover;
  //min-height: 100vh;
  padding: 2rem;
  height: 788px;
}

.illustration {
  max-width: 100%;
  height: auto;
}

.email-input {
  max-width: 500px; /* Adjust the width as needed */
  height: 60px;
}
.email-input::placeholder{
  color: #868AB0;
  font-family: 'Outfit regular', sans-serif;
}

.rounded-btn, .rounded-btn-clone, .trying-btn {
  height: 60px!important;
  width: 40%!important;
  color: #fff;
  border-radius: 30px;
  padding: 12px 24px 12px 24px!important;
  font-family: 'Outfit medium';
}
.rounded-btn-clone{
  display: none;
}
.email-container {
  max-width: 525px;
  border-radius: 30px;
  border: 1px solid #DEDBE3;
}
.email-input {
  padding: 10px 20px;
  font-size: 16px;
  outline: none;
  width: 60%;
  background-color: transparent;
}
.text-banner-title{
  line-height: 1.2em;
  font-size: 64px;
}
.text-service{
  gap: 32px;
  flex-wrap: wrap;
}
.text-service>span, .text-subtitle{
  color: #868ab0!important;
}
.px-10{
  padding-left: 80px!important;
  padding-right: 80px!important;
}
.ad-platforms{
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  padding: 50px 0 50px 0;
  background: linear-gradient(to right, #090F49, #0146d5, #090F49);
  gap: 48px;
}
.text-ad-platforms{
  font-size: 24px;
  color: #fff;
}
.logo-ad-platforms{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 86px;
}
.better-adds{
  display: flex;
  flex-direction: column;
  text-align: center;
  //height: 1000px;
  background-color: #F5F7FA;
  //justify-content: center;
  padding: 100px 20px 100px 20px;
  gap: 60px;
}
.title-better-adds{
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  width: 60%;
  margin: 0 auto;
}
.better-adds-section{
  display: flex;
  background-color: #fff;
  gap: 60px;
  padding: 35px 20px 30px 20px;
  max-width: 1050px;
  border-radius: 20px;
  margin: 0 auto;
  justify-content: center;
  box-shadow:
      0px 4px 25px rgba(4, 17, 88, 0.07),
      0px -4px 17px rgba(4, 17, 88, 0.03);
}
.better-adds-section h2{
  font-size: 24px;
}
.better-adds .trying-btn{
  max-width: 214px!important;
  margin-top: 60px!important;
}
.benefits-section{
  background-color: #ffffff;
  padding: 100px 80px 100px 80px;
  gap: 64px;
}

.benefit-feature img{
  width: 100%;
}
.benefit-feature h2{
  font-size: 48px;
  line-height: 48px;
  margin-bottom: 40px;
}
.benefit-feature .save{
  margin-bottom: 16px!important;
}
.benefit-feature p{
  margin-bottom: 4px!important;
}
.affordable{
  gap: 10px;
  padding: 10px 16px 10px 16px;
  background-color: #E4F5FF;
  color: #2981E9;
  width: fit-content;
  border-radius: 100px;
  margin-bottom: 40px!important;
}
.reasons-pivot-title{
  width: 60%;
  margin: 0 auto 60px auto;
  text-align: center;
}
.reasons-pivot-title h2{
  margin-bottom: 24px;
}
.reason-icon-bg{
  padding: 15px;
  background-color: #E4F5FF;
  border-radius: 80px;
  align-items: center;
  height: fit-content;
}
.reasons-content-item{
  gap: 16px;
}
.reasons-pivot-content{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  width: 77%;
  margin: 0 auto;
}
.reasons-pivot-content h3{
  line-height: 24px;
}
.reasons-pivot-content p{
  width: 85%;
  line-height: 24px;
}
.feedback-section{
  padding: 100px 0;
  width: 90%;
  margin: 0 auto;
}
.feedback-section img{
  object-position: top;
  object-fit: cover;
}
.w-80p{
  width: 80%;
}
.social-media{
  gap: 24px;
}
.footer-menus a{
  text-decoration: none;
  color: white;
}
.footer p{
  margin: 0;
}
.footer-menus{
  gap: 16px;
  flex-wrap: wrap;
}

:deep(.v-window__prev){
  display: none!important;
}
:deep(.v-window__next){
  display: none!important;
}
:deep(.v-carousel){
  height: 250px!important;
}
:deep(.v-carousel__item){
  height: fit-content!important;
}
:deep(.feedback-section .v-sheet.v-card){
  border-radius: 20px;
  border-color: #10A6FA;
}
:deep(.feedback-section .v-btn--icon.v-size--small){
  background-color: #DEDBE3;
  max-width: 100px;
  //width: unset!important;
  height: 6px;
  border-radius: 4px;
}
:deep(.feedback-section .v-btn--icon.v-btn--active){
  background-color: #3D7FFF;
}
:deep(.v-btn--icon.v-size--small span){
  display: none;
}
:deep(.v-carousel__controls){
  display: flex!important;
  background-color: transparent!important;
}
:deep(.footer .v-toolbar__content){
  justify-content: space-between;
}
.footer{
  padding: 32px 80px;
  background-color: #090F49;
  color: white;
}
@media screen and (max-width: 960px) {
  .hero-section{
    background: url("../../assets/gradient_background_mobile.png") no-repeat center!important;
    background-size: cover!important;
  }
  .rounded-btn-clone{
    display: unset!important;
    width: 250px!important;
  }
  .rounded-btn{
    display: none !important;
  }
  .email-container{
    width: 100%;
  }
  .hero-section{
    height: unset!important;
  }
  .illustration{
    margin: 26px auto!important;
  }
  .text-right{
    text-align: center!important;
  }
  .title-better-adds{
    font-size: 36px;
  }
  .better-adds-section{
    gap: 30px;
  }
  .better-adds-section img{
    width: 100%;
  }
  .benefit-feature:nth-child(2){
    flex-direction: column-reverse;
    .justify-end{
      justify-content: flex-start!important;
    }
  }
}
@media screen and (max-width: 660px) {
  .text-banner-title{
    font-size: 32px!important;
  }
  .text-service{
    flex-direction: column;
    gap: 20px;
  }
  .custom-bg{
    padding: 12px 40px 12px 40px!important;
    height: 70px!important;
  }
  .logo img{
    width: 100px!important;
  }
  .hero-section{
    padding-left: 40px!important;
    padding-right: 40px!important;
  }
  .rounded-btn-clone, .trying-btn{
    width: 100%!important;
  }
  .logo-ad-platforms{
    gap: 32px;
  }
  .ad-platforms{
    padding-left: 20px!important;
    padding-right: 20px!important;
  }
  .text-ad-platforms{
    font-size: 20px;
  }
  .title-better-adds{
    font-size: 24px;
    line-height: 34px;
    width: 80%;
  }
  .better-adds, .benefits-section, .reasons-pivot-content, .footer{
    padding: 50px 20px 60px 20px!important;
  }
  .reasons-pivot-title{
    width: 92%;
  }
  .reasons-pivot-content{
    padding-top: 0!important;
    padding-bottom: 0!important;
    width: unset!important;
  }
  .better-adds-section{
    flex-direction: column;
  }
  .better-adds-section img{
    width: 80%;
  }
  .w-80p{
    width: 100%;
  }
  .better-adds-section h2{
    font-size: 20px;
  }
  .reasons-pivot-content{
    grid-template-columns: repeat(1, 1fr)!important;
  }
  .feedback-section{
    padding: 34px 0 60px 0;
  }
  .feedback-section h2{
    text-align: left;
    margin: 0;
  }
  .feedback-section .pt-15{
    padding-top: 27px!important;
  }
  .footer .custom-bg{
    padding: 0!important;
  }
  .footer-menus{
    flex-direction: column;
    justify-content: flex-end;
    gap: 4px;
  }
  .footer .font-family-outfit-regular{
    align-items: end;
  }

}
@media screen and (max-width: 450px) {
  .custom-bg, .hero-section{
    padding-left: 20px!important;
    padding-right: 20px!important;
  }
  .logo{
    padding: 0!important;
    margin: 0!important;
    img{
      width: 80px!important;
      padding: 0!important;
      margin: 0!important;
    }
  }
}
</style>

